

.interface {
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(180deg, rgba(0,0,0,.5), rgba(8, 78, 170,.25), rgba(0,0,0,.5));
}
.interface__container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	flex-flow: column;
	position: relative;
	width: 100%;
	padding: 0 15px;
	overflow: hidden;
}
.interface__rings {
	justify-content: center;
	align-items: center;
	transition: .4s linear transform;
	position: absolute;
	transform: rotate(0);
	width: 100%;
	height: 100%;
	display: none;
}
.interface__ring {
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	margin: auto;
	width: 75%;
	height: 75%;
	border: 1px solid $color-primary;
	border-radius: 50%;
	// background: radial-gradient(rgba(8, 78, 170,.25), rgba(0,0,0,.5));
}
.interface__ring--inner {
	background: radial-gradient(rgba(0,0,0,.75) 0% 50%, rgba(8,78,170,.5));
	// background: radial-gradient($color-background, rgb(33, 33, 180) 35% 35%, green 50%, red 100%);
}
.interface__ring--border {
}
.interface__ring--outer {
	width: 95%;
	height: 95%;
	margin: auto;
	background: 
		radial-gradient( rgba(0, 25, 39, 0.75) 25%, rgba(0, 0, 0, 0.1)),
		radial-gradient( rgba(0, 150, 250, 0.95) 25%, rgba(255, 0, 119, .5) 100%),
		linear-gradient(to top,transparent 70%, rgb(255, 0, 255) 100%);
	border: 0;
}
.interface__ring--glow {
	width: 100%;
	height: 100%;
	border: 1px solid $color-secondary;
}
.interface__panel {
	position: absolute;
	padding: 1rem;
	right: 10%;
	top: 10%;
	width: 25vw;
	height: 15vw;
	border: 1px solid $color-secondary;
	border-radius: 3px;
	box-shadow: 0 0 15px 0 $color-primary;
	background: rgba(0, 79, 124, 0.25);
}
.interface__screen {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(15, 235, 132, 0.15);  
	backdrop-filter: blur(5px);
}
.interface__buttons {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	position: relative;
	margin: 5px 0 20px;
}
.interface__button {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: block;
	padding: 0 0 5px;
	margin: 0 6px;
	position: relative;
	&:after {
		content: '';
		width: 40px;
		height: 1px;
		background: $color-active;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		transform: scaleX(0);
		transform-origin: center left;
		transition: .3s ease-in all;
	}
	&:hover,&:focus, &.active {
		.button-icon {
			fill: $color-active;
		}
		&:after {
			transform-origin: center right;
			transform: scaleX(1);
		}
	}
}
.interface__underline {
	position: absolute;
	bottom: -6px;
	left: 0;
	width: 35px;
	height: 1px;
	background: $color-tertiary;
	transition: .3s ease-in-out left;
}
.button-icon {
	fill: $color-white;
	width: 100%;
	height: 100%;
	transition: .2s ease-in fill;
}
.interface__svg {
	width: 65%;
	height: 65%;
	position: absolute;
	// stroke-dashoffset: 20;
	stroke-dasharray: 31.4 31.4;
	stroke-width: 0.25;
	stroke: $color-white;
	fill: none;
	left: 0;
	right: 0;
	margin: auto;
	top: 50%;
	transform: translateY(-50%);
	// animation: centerRotate 5s linear infinite;
}
.interface__content {
	height: 90vw;
	width: 90vw;
	max-width: 450px;
	max-height: 450px;
	z-index: 20;
	display: flex;
	justify-content: center;
	transform: rotate(0);
	transition: .4s linear transform;
}

@include medium {
	.interface__container {
		width: 750px;
		height: 750px;
	}
	.interface__content {
		max-width: none;
		max-height: none;
		width: 55%;
		height: 55%;
		align-items: center;
		position: absolute;
		@include center;
	}
	.interface__rings {
		display: flex;
	}
	.interface__svg {
		transition: .5s ease-in width, .5s ease-in height;
	}
	.interface__svg--stocks {
		width: 72%;
		height: 72%;
	}
	.interface__svg--weather {
		width: 40%;
		height: 40%;
	}
	.interface__buttons {
		width: 95%;
		height: 95%;
		position: absolute;
		@include center;	
	}
	.interface__button {
		position: absolute;
		width: 65px;
		height: 65px;
		margin: 0;
	}
	.interface__underline {
		display: none;
	}
}

@media (max-height: 44em) {
	.interface__container {
		width: 600px;
		height: 600px;
	}
}

.grid {
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-template-rows: minmax(min-content, 1fr);
	grid-gap: 20px;
	.item {
		background: lime;
		padding: 25px;
	}
	.item-1 {
		background: orange;
		grid-row: 1/2;
		grid-column: 1/2;
		align-self: start;
	}
	.item-2 {
		background: blue;
		grid-row: 2/3;
		grid-column: 1/2;
	}
	.item-3 {
		background: red;
		grid-row: 3/4;
		grid-column: 1/2;
	}
	.item-4 {
		background: yellow;
		grid-row: 1/2;
		grid-column: 2/3;
		align-self: start;
	}
	.item-4 {
		background: lime;
		grid-row: 2/3;
		grid-column: 2/3;
		align-self: start;
	}
}