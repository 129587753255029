
$color-white: #fff;
$color-black: #000;

$color-background: rgb(10, 8, 8);
$color-primary: rgb(36, 138, 255);
$color-secondary: rgb(0, 160, 252);
$color-tertiary: rgb(3, 205, 255);
$color-dark: rgb(2, 2, 73);
$color-glow: rgb(255,0,255);
$color-orange: rgb(236, 181, 0);
$color-active: rgb(0, 250, 0);
$color-red: red;
$pi: 3.14159265358979;  
$font-primary: 'Montserrat', sans-serif;