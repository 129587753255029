.calculator {
	display: none;
	width: 100%;
	padding: 0;
	&.active {
		display: block;
	}
}
.form {

}
.form__label {
	width: 100%;
	margin: 0 0 5px;
	font-size: 1rem;
	display: block;
	position: relative;
}
.form__input {
	width: 100%;
	padding: .25rem 1em .25rem 20px;
	font-size: 1.125rem;
	height: 30px;
	background: $color-black;
	color: $color-tertiary;
	border: 1px solid $color-secondary;
	border-radius: 5px;
}
.form__input--clear {
	padding: .5rem;
}
.form__input-wrapper {
	position: relative;
	margin: 5px 0 0;
}
.form__input-symbol {
	display: inline-block;
	position: absolute;
	font-size: 1.125rem;
	left: 5px;
	top: 50%;
	transform: translateY(-50%);
	color: $color-tertiary;
}
.form__input-symbol--secondary {
	left: auto;
	right: 5px;
}
.form__total {
	font-size: 1.25rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


@include medium {
	.form__input {
		height: 45px;
	}
	.form__label {
		font-size: 1.25rem;
	}
	.calculator {
		padding: 25px;
	}
}