

@mixin small {
  @media (min-width: 48em) { @content; }
}
@mixin medium {
  @media (min-width: 60em) { @content; }
}
@mixin large {
  @media (min-width: 72em) { @content; }
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
@keyframes centerRotate {
  from {
    transform: translateY(-50%) rotate(0);
  }
  to {
    transform: translateY(-50%) rotate(360deg);
  }
}

@keyframes seconds {
  from {
    stroke-dashoffset: 157
  }
  to {
    stroke-dashoffset: 0;
  }
}

@mixin center {
  top: 50%;
  transforM: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
}